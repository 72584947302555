.container {
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
    width: 100%;
    margin-top: calc(var(--margin-top-content) - 2rem);
}

.contactContainer {
    display: grid;
    gap: var(--gap-container);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
}

.officeContainer {
    display: grid;
    grid-gap: var(--gap-container);
    grid-template-columns: repeat(9, 1fr);
}

.officeContactContainer {
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
    padding: var(--padding-container);
    border-radius: var(--border-radius-container);
    background-color: var(--light-color);
    grid-column: 1 / 4;
}

.mapContainer {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-container);
    background-color: var(--light-color);
    grid-column: 4 / 10;
}

.titleText {
    font-family: var(--font-family-heading);
    font-weight: 600;
}

.someContainer {
    margin-top: auto;
}

.officeBlockContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.map {
    border: 0;
    border-radius: var(--border-radius-container);
}

@media (max-width: 1200px) {
    .contactContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .contactContainer {
        grid-template-columns: repeat(2, 1fr);
    }
    .officeContactContainer {
        grid-column: 1 / 5;
    }

    .mapContainer {
        grid-column: 5 / 10;
    }
}

@media (max-width: 490px) {
    .officeContactContainer {
        grid-column: 1 / 10;
    }

    .mapContainer {
        grid-column: 1 / 10;
    }
}

@media (max-width: 600px) {
    .contactContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

.colorSecondary {
    color: var(--secondary-color);
}