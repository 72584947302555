.container {
    display: flex;
    flex-direction: column;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0.75rem;
    position: relative;
}

.thumbnailContainer {
    composes: container;
    margin-top: 1rem;
}

.thumbnail {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius-container);
}

.avatarContainer {
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
}

.avatarImg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.contentContainer {
    composes: container;
    margin-top: 1rem;

    gap: 0.85rem;

    font-size: calc(var(--font-size-body) * 1.1);
    -webkit-font-smoothing: antialiased;
    color: #292929;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    letter-spacing: -.054px;
    line-height: 28px;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.contentContainer p, .contentContainer ol, .contentContainer ul, .contentContainer blockquote, .contentContainer pre, .contentContainer table, .contentContainer hr, .contentContainer .gatsby-highlight, .contentContainer .twitter-tweet, .contentContainer .instagram-media, .contentContainer .wp-block-embed__wrapper, .contentContainer .blocks-gallery-grid {
    margin: 0;
    padding: 0;
}

.headingContainer {
    composes: container;
    margin-top: var(--margin-top-content);
}


.contentContainer img, .contentContainer video {
    max-width: 100%;
    height: auto;
    border-radius: var(--border-radius-container);
}

.contentContainer a {
    color: var(--primary-color);
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    opacity: var(--opacity-idle);
}

.contentContainer a:hover {
    opacity: var(--opacity-hover);
}

.contentContainer a:active {
    opacity: var(--opacity-active);
}

.contentContainer h1, .contentContainer h2, .contentContainer h3, .contentContainer h4, .contentContainer h5, .contentContainer h6 {
    margin-top: 0.65rem;
}

.metaContainer {
    composes: container;
    margin-top: 1rem;
    gap: 0.85rem;
    display: flex;
    flex-direction: row;
}

.authorContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(var(--font-size-body) * 0.9);
    font-weight: 400; /* 300 */
}

.shareContainer {
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

.shareButton {
    cursor: pointer;
    font-weight: 700;
    color: var(--dark-color);
    opacity: var(--opacity-idle);
    font-size: calc(var(--font-size-body) * 1.1);
}

.shareButton:hover {
    opacity: var(--opacity-hover);
}

.shareButton:active {
    opacity: var(--opacity-active);
}

.backButtonContainer {
    composes: container;
    display: flex;
    margin-top: var(--gap-container);
    padding-top: var(--gap-container);
    border-top: 1px solid var(--text-muted);
}

.backButton {
    cursor: pointer;
    font-weight: 700;
    color: var(--dark-color);
    opacity: var(--opacity-idle);
    font-size: var(--font-size-body);
    gap: 0.5rem;
    display: flex;
    align-items: center;
    width: auto;
    margin-right: auto;
    text-decoration: underline;
}

.backButton:hover {
    opacity: var(--opacity-hover);
}

.backButton:active {
    opacity: var(--opacity-active);
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: var(--margin-top-content);
}

.errorImage {
    max-width: 30px;
    width: 100%;
    height: auto;
}