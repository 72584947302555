.container {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-container);
    background: var(--light-color);
    overflow: hidden;
    cursor: pointer;
    color: var(--dark-color);
    text-decoration: none;
    transition: all 0.3s ease-in-out;

}

.container:hover {
    /* box-shadow: 0 0 9px -1px color(srgb 0.23 0.23 0.23 / 0.39); */
}

.container:hover .titleText {
    text-decoration: underline;
}

.container:hover .image {
    transform: scale(1.015);
}

.container:hover .noImage {
    transform: scale(1.4);
}

.image {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: var(--border-radius-container);
    border-top-right-radius: var(--border-radius-container);
    transition: all 0.3s ease-in-out;
}

.noImage {
    composes: image;
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
    background-color: #ebebeb;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.4rem;
    flex: 1;
}

.categoryText {
    font-size: calc(var(--font-size-body) * 0.85);
    font-weight: 400; /* 300 */
    color: var(--text-muted);
    text-transform: uppercase;
}

.dateText {
    font-size: calc(var(--font-size-body) * 0.85);
    font-weight: 400;
    color: var(--text-muted);
    margin-top: auto;
}

.titleText {
    font-size: var(--font-size-h2);
    font-weight: 800;
    font-family: var(--font-family-heading);
    text-decoration: none;
    color: var(--dark-color);
}

.skeleton {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    line-height: normal !important;
}