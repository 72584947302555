.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
    margin-top: calc(var(--margin-top-content) - 2rem);
}

.mapContainer {
    width: 100%;
    display: flex;
}

.map {
    box-shadow: none;
    border-radius: var(--border-radius-container);
    border: none;
}

.productsContainer {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 992px) {
    .productsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}





/** TEMP **/

.contactContainer {
    display: grid;
    gap: var(--gap-container);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
}

@media (max-width: 1200px) {
    .contactContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media (max-width: 992px) {
    .contactContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .contactContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
