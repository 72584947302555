.containerFluid {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--dark-color);
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.row {
    display: grid;
    gap: 2.25rem;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
}

.columnMain {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    grid-column: 1 / 5;

}

.column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.columnTwo {
    composes: column;
    grid-column: 5 / 7;
}

.columnThree {
    composes: column;
    grid-column: 7 / 9;
}

.columnFour {
    composes: column;
    grid-column: 9 / 11;
}





.logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    max-width: 150px;
    width: 100%;
    height: auto;
}

.columnText {
    font-weight: 400; /* 300 */
    color: var(--light-color);
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
}

.button {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: var(--font-size-body);
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1.5px solid;
    border-color: transparent;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: var(--light-color);
    opacity: var(--opacity-idle);
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}


.buttonSecondary {
    composes: button;
    border-color: var(--light-color);
    color: var(--light-color);
    background: transparent;
}

.columnTitle {
    font-size: var(--font-size-h3);
    font-weight: 600;
    font-family: var(--font-family-heading);
    color: var(--light-color);
    text-transform: uppercase;
    padding-bottom: 0.25rem;
}

.columnLink {
    font-size: var(--font-size-body);
    font-weight: 400;
    color: var(--light-color);
    text-decoration-line: none;
    opacity: var(--opacity-idle);
    cursor: pointer;
}

.columnLink:hover {
    opacity: var(--opacity-hover);
}

.columnLink:active {
    opacity: var(--opacity-active);
}

.copyrightContainer {
    border-top: 0.5px solid var(--text-muted);
    padding-top: 2rem;
    margin-top: 2rem;
}

.copyrightText {
    font-weight: 400;
    color: var(--light-color);
}

@media (max-width: 1200px) {
    .logoContainer {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 992px) {
    .row {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1.25rem;
    }
    .columnMain {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }
    .columnTwo {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    .columnThree {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    .columnFour {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
}

.marginTop {
    margin-top: var(--gap-container);
}

@media (max-width: 410px) {
    .row {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 1.25rem;
    }
    .columnMain {
        grid-column: 1 / 5;
        grid-row: 1 / 2;
    }
    .columnTwo {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
    }
    .columnThree {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }
    .columnFour {
        grid-column: 3 / 5;
        grid-row: 3 / 4;
    }
}
