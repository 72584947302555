.item {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.headingContainer {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
}

.headingText {
    font-size: var(--font-size-h3);
    font-weight: 600;
    color: var(--light-color);
    font-family: var(--font-family-heading);
}

.headingIconContainer {

}

.headingIcon {
    width: 35px;
    height: 35px;
}

.itemText {
    font-size: var(--font-size-body);
    font-weight: 400;
    color: var(--light-color);
    font-family: var(--font-family-body);
}