.wrapper {
    width: 100%;
    padding: 4.5rem 0;
    background: var(--light-color);
    margin-top: 4rem;
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--gap-container);
}

.headingText {
    font-size: var(--font-size-xxl);
    font-weight: 800;
    color: var(--dark-color);
    font-family: var(--font-family-heading);
    margin: 0;
    padding: 0;
}

.leadText {
    font-size: var(--font-size-body);
    font-weight: 400;
    color: var(--dark-color);
    font-family: var(--font-family-body);
}

.leadContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.button {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: 1rem;
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    border: 1.5px solid;
    border-color: transparent;
    opacity: var(--opacity-idle);
    margin-right: auto;
    background-color: var(--dark-color);
    cursor: pointer;
    color: var(--light-color);
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}

.icon {
    font-size: 1.2rem;
}


.wrapperBottomRow {
    width: 100%;
    padding: 4.5rem 0;
    background: rgb(22,25,33);
    background: linear-gradient(180deg, rgba(22,25,33,1) 35%, rgba(11,27,40,1) 100%);
}

.containerBottomRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--gap-container);
}

.imgContainer {
    border-radius: 0.75rem;
    background: rgb(220,235,248);
    background: linear-gradient(180deg, rgba(220,235,248,1) 0%, rgba(227,236,235,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
    max-width: 60%;
    padding: 2rem;
    height: auto;
}

@media (max-width: 685px) {
    .container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 450px) {
    .containerBottomRow {
        grid-template-columns: 1fr 1fr;
    }
}