.container {
    display: flex;
    flex-direction: column;
}

.separator {
    width: 100%;
    height: 1px;
    background: var(--dark-color);
    margin: calc(var(--gap-container) * 1.5) 0;
}

.questionContainer {
    display: grid;
    grid-template-columns: 1fr 4fr 5fr;
    gap: var(--gap-container);
}

@media (max-width: 500px) {
    .questionContainer {
        grid-template-columns: 1fr;
    }
}

.orderText {
    font-weight: 700;
    font-family: var(--font-family-heading);
}

.questionText {
    font-weight: 700;
    max-width: 400px;
    font-family: var(--font-family-heading);
}

.answerText {
    font-weight: 400; /* 300 */
}