.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    margin-top: calc(var(--gap-container) * 3);
    padding: 2rem 0;
}

.textBg {
    background-color: var(--tertiary-color);
    color: var(--light-color);
    padding: 0.25rem 0.65rem;
    align-self: flex-start;
    border-radius: 0.35rem;
    font-weight: 600;
}

.headingText {
    font-family: var(--font-family-heading);
    font-weight: 800;
    font-size: var(--font-size-xxl);
}

.leadText {
    font-size: var(--font-size-h3);
}

.button {
    align-self: flex-start;
    gap: 0.65rem;
}

.buttonLight {
    composes: button;
    background-color: var(--light-color);
    color: var(--dark-color);
}

.buttonsContainer {
    display: flex;
    gap: 0.65rem;
    margin-top: 1rem;
}