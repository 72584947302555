.wrapper {
    width: 100%;
    padding: 4.5rem 0;

}

.colorSchemePrimary {
    background: var(--primary-color);
    color: var(--light-color);
}

.colorSchemePrimary button {
    background-color: var(--light-color);
    color: var(--primary-color);
}

.colorSchemeSecondary {
    background: var(--secondary-color);
    color: var(--light-color);
}

.colorSchemeSecondary button {
    background-color: var(--light-color);
    color: var(--secondary-color);
}

.colorSchemeTertiary {
    background: var(--tertiary-color);
    color: var(--light-color);
}

.colorSchemeTertiary button {
    background-color: var(--light-color);
    color: var(--tertiary-color);
}

.colorSchemeLight {
    background: var(--light-color);
    color: var(--dark-color);
}

.colorSchemeLight button {
    background-color: var(--dark-color);
    color: var(--light-color);
}

.colorSchemeDark {
    background: var(--dark-color);
    color: var(--light-color);
}

.colorSchemeDark button {
    background-color: var(--light-color);
    color: var(--dark-color);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25rem;
    max-width: 500px;
}

.containerLeft {
    align-items: flex-start;
    text-align: left;
    margin: 0;
}

.containerCenter {
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.containerLeft button {
    margin-left: 0;
    margin-right: auto;
}

.containerCenter button {
    margin-left: auto;
    margin-right: auto;
}

.heading {
    font-size: var(--font-size-h1);
    font-weight: 800;
    font-family: var(--font-family-heading);
}

.text {
    font-family: var(--font-family-body);
}

.button {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: 1rem;
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1.5px solid;
    border-color: transparent;
    opacity: var(--opacity-idle);
    max-width: 180px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 0.5rem;
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}