.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.textColumn {
    composes: column;
    justify-content: center;
}

.leadText {
    text-transform: uppercase;
    font-size: 1rem;
    font-family: var(--font-family-heading);
    font-weight: 600;
    color: var(--secondary-color);
}

.headingText {
    font-size: 2.5rem;
    font-family: var(--font-family-heading);
    font-weight: 800;
    color: var(--dark-color);
}

.stepsTitleText {
    font-size: 1.4rem;
    font-family: var(--font-family-heading);
    font-weight: 800;
    color: var(--dark-color);
}

.stepContainer {
    display: grid;
    grid-template-columns: 42px 1fr;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.stepNumber {
    font-size: 1.4rem;
    font-family: var(--font-family-heading);
    font-weight: 800;
    color: var(--light-color);
    background-color: var(--secondary-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
}

.stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.leadCardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    border-radius: var(--border-radius-container);
    background-color: var(--light-color);
    height: 100%;
    box-shadow: 0 2px 6px 1px rgba(202, 202, 209, 0.7);
}

.inputText {
    font-size: 1rem;
    font-weight: 400;
    color: var(--dark-color);
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    outline-color: var(--secondary-color);
    line-height: 1.625rem;
    background-color: var(--light-color);
    box-shadow: none;
    border: 1px solid var(--grey-color);
    width: 100%;
}

.inputText::placeholder, .inputText::-ms-input-placeholder, .inputText::-webkit-input-placeholder {
    color: rgb(148, 163, 184);
}

.inputContainer {
    display: flex;
    width: 100%;
}

.termsText {
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--dark-color);
}

.newsletterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.button {
    font-weight: 500;
    font-size: 1rem;
    text-decoration-line: none;
    padding: 0.65rem 1.2rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid;
    border-color: transparent;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: var(--light-color);
    opacity: var(--opacity-idle);
    min-width: 152px;
    min-height: 41px;
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 1rem 0;
    height: 40px;
    width: 100%;
}

.logo {
    height: 100%;
    width: auto;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footerContainer {
    display: flex;
    justify-content: center;
    padding: 1rem 0 2rem 0;
}

.footerText {
    align-self: center;
    text-align: center;
}

.circle {
    --circle-size: 600px;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 100%;
    background-color: var(--tertiary-color);
    position: absolute;
    z-index: -1;
    top: calc(-1 * (var(--circle-size) / 2));
    right: calc(-1 * (var(--circle-size) / 2));
    overflow: hidden;
}

.circleContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
}

@media (max-width: 1200px) {
    .container {
        flex-direction: column;
        gap: 2rem;
    }
    .leadText {
        text-align: center;
    }
    .headingText {
        text-align: center;
    }
    .stepsTitleText {
        text-align: center;
    }
    .circle {
        display: none;
    }
    .flexColumn {
        gap: 1.5rem;
    }
}

@media (max-width: 992px) {
    .leadCardContainer {
        padding: 1.5rem;
    }
    .inputText {
        padding: 0.85rem 1.35rem;
    }
    .headingText {
        font-size: 2.0rem;
    }
    .flexColumn {
        gap: 0.5rem;
    }
    .stepsTitleText {
        font-size: 1.3rem;
    }
}

.link {
    text-decoration: underline;
    color: var(--secondary-color);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    opacity: var(--opacity-idle);
}

.link:hover {
    opacity: var(--opacity-hover);
}

.link:active {
    opacity: var(--opacity-active);
}

.invalid {
    border-color: var(--danger-color);
}

.successContainer {
    height: 529px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    border-radius: var(--border-radius-container);
    background-color: var(--light-color);
    box-shadow: 0 2px 6px 1px rgba(202, 202, 209, 0.7);
}

.successText {
    text-align: center;
}

.successCheckmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
}

.successCheckmark .checkIcon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
}

.successCheckmark .checkIcon::before,
.successCheckmark .checkIcon::after {
    content: '';
    height: 100px;
    position: absolute;
    background: #FFFFFF;
    transform: rotate(-45deg);
}

.successCheckmark .checkIcon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.successCheckmark .checkIcon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.successCheckmark .checkIcon .iconLine {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.successCheckmark .checkIcon .iconLine.lineTip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
}

.successCheckmark .checkIcon .iconLine.lineLong {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
}

.successCheckmark .checkIcon .iconCircle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, .5);
}

.successCheckmark .checkIcon .iconFix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
}


@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.successButtonContainer {
    color: var(--secondary-color);
    opacity: var(--opacity-idle);
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.successButtonContainer:hover {
    opacity: var(--opacity-hover);
}

.successButtonContainer:active {
    opacity: var(--opacity-active);
}