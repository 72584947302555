/* App.css - Global styles */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,600;9..40,700;9..40,800;9..40,900&family=Nunito:wght@400;500;600;700&display=swap');

:root {
    /* Color definitions */
    --background-color: #D9D9D9;
    --primary-color: #2196f3;
    --secondary-color: #477FA1;
    --tertiary-color: #983C6C;
    --dark-color: #041017;
    --light-color: #FFF;
    --text-muted: #707070;
    --grey-color: #cbd5e2;
    --danger-color: #ff6b6b;

    /* Define the maximum container width for different screen sizes */
    --container-max-width-xs: 100%; /* Extra small devices (phones, less than 768px) */
    --container-max-width-sm: 720px; /* Small devices (tablets, 768px and up) */
    --container-max-width-md: 960px; /* Medium devices (desktops, 992px and up) */
    --container-max-width-lg: 1140px; /* Large devices (large desktops, 1200px and up) */
    --container-max-width-xl: 1320px; /* Extra large devices (larger desktops, 1400px and up) */
    --container-padding: 15px;

    /* Define opacities on mouse events */
    --opacity-idle: 1;
    --opacity-hover: 0.9;
    --opacity-active: 0.8;

    /* Define the font sizes */
    --font-size-body: 1rem;
    --font-size-h3: 1.25rem;
    --font-size-h2: 1.5rem;
    --font-size-h1: 2rem;
    --font-size-xxl: calc(var(--font-size-h1) * 1.5);

    /* Define the border radius */
    --border-radius-button: 0.5rem;
    --border-radius-container-sm: 1.45rem;
    --border-radius-container-md: 1.6rem;
    --border-radius-container-lg: 1.75rem;

    --border-radius-container: var(--border-radius-container-md);

    /* Define the paddings */
    --padding-container-sm: 1rem;
    --padding-container-md: 1.25rem;
    --padding-container-lg: 1.5rem;

    --padding-container: var(--padding-container-md);

    /* Define the fonts */
    --font-family-heading: 'DM Sans', sans-serif;
    --font-family-body: 'Nunito', sans-serif;
    --font-family-button: 'DM Sans', sans-serif;

    /* Define the font weights */
    --font-weight-button: 600;

    /* Define the gaps */
    --gap-container-sm: 1.2rem;
    --gap-container-md: 1.5rem;
    --gap-container-lg: 2rem;

    --gap-container: var(--gap-container-lg);

    --margin-top-content: calc(64px + var(--gap-container));
}

body {
    font-family: 'Nunito', sans-serif;
    background-color: var(--background-color);
    color: var(--dark-color);
    font-size: var(--font-size-body);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-heading);
    margin: 0;
    padding: 0;
}

h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

.container {
    width: 100%;
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    display: flex;
}

/* Extra small devices (phones, less than 768px) */
/* No media query for 'xs' since this is the default in mobile-first approach */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        max-width: var(--container-max-width-sm);
    }
}

@media (max-width: 500px) {
    :root {
        --padding-container: var(--padding-container-sm);
        --border-radius-container: var(--border-radius-container-sm);
        --gap-container: var(--gap-container-sm);

        --font-size-body: 0.94rem;
        --font-size-h3: 1.13rem;
        --font-size-h2: 1.30rem;
        --font-size-h1: 1.70rem;
        --font-size-xxl: calc(var(--font-size-h1) * 1.2);
    }
}

@media (max-width: 992px) {
    :root {
        --padding-container: var(--padding-container-sm);
        --border-radius-container: var(--border-radius-container-sm);
        --gap-container: var(--gap-container-sm);

        --font-size-body: 0.95rem;
        --font-size-h3: 1.15rem;
        --font-size-h2: 1.35rem;
        --font-size-h1: 1.75rem;
        --font-size-xxl: calc(var(--font-size-h1) * 1.3);
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    :root {
        --padding-container: var(--padding-container-md);
        --border-radius-container: var(--border-radius-container-md);
        --gap-container: var(--gap-container-md);

        --font-size-body: 1rem;
        --font-size-h3: 1.2rem;
        --font-size-h2: 1.4rem;
        --font-size-h1: 1.9rem;
        --font-size-xxl: calc(var(--font-size-h1) * 1.4);
    }

    .container {
        max-width: var(--container-max-width-md);
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    :root {
        --padding-container: var(--padding-container-lg);
        --border-radius-container: var(--border-radius-container-lg);
        --gap-container: var(--gap-container-lg);

        --font-size-body: 1rem;
        --font-size-h3: 1.25rem;
        --font-size-h2: 1.5rem;
        --font-size-h1: 2rem;
        --font-size-xxl: calc(var(--font-size-h1) * 1.5);
    }

    .container {
        max-width: var(--container-max-width-lg);
    }
}

/* Extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .container {
        max-width: var(--container-max-width-xl);
    }
}

.ybVideoContainer {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* Aspect Ratio 16:9 */
    height: 0;
    border-radius: var(--border-radius-container);
}

.ybVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-container);
}

button {
    transition: opacity 0.1s ease-in-out;
    opacity: var(--opacity-idle);
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: 1rem;
    text-decoration-line: none;
    padding: 0.65rem 1.2rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid;
    border-color: transparent;
    cursor: pointer;
    background-color: var(--dark-color);
    color: var(--light-color);
}

button:hover {
    opacity: var(--opacity-hover);
}

button:active {
    opacity: var(--opacity-active);
}

button:disabled,
button[disabled]{
    cursor: not-allowed;
    opacity: 0.6 !important;
}

input:focus {
    outline: 2px solid var(--primary-color);
}

a {
    text-decoration: none;
    color: var(--primary-color);
    opacity: var(--opacity-idle);
}

a:hover {
    opacity: var(--opacity-hover);
}

a:active {
    opacity: var(--opacity-active);
}

