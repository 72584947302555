.wrapper {
    background: var(--dark-color);
    padding-bottom: var(--gap-container);
}

.marginTop {
    margin-top: var(--gap-container);
}

.container {
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
}

.row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-container);
}

.item {
    padding: var(--padding-container);
    gap: 1rem;
    background-color: var(--light-color);
    border-radius: var(--border-radius-container);
    display: flex;
    flex-direction: column;
}

.itemTitle {
    font-size: var(--font-size-h2);
    font-weight: 800;
    font-family: var(--font-family-heading);
}

.itemText {
    font-weight: 400; /* 300 */
    color: var(--text-muted);
}

.itemArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: auto;
    background-color: var(--secondary-color);
    border-radius: 100%;
    cursor: pointer;
    opacity: var(--opacity-idle);
    text-decoration: none;
    margin-top: auto;
}

.itemArrowImg {
    max-width: 100%;
    height: auto;
}

.itemArrowContainer:hover {
    opacity: var(--opacity-hover);
}

.itemArrowContainer:active {
    opacity: var(--opacity-active);
}

@media (max-width: 1200px) {
    .row {
        grid-template-columns: 1fr;
    }
    .item {
        width: auto;
    }
}