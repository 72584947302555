.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.heading {
    font-size: var(--font-size-h1);
    font-weight: 800;
    text-align: center;
    color: var(--dark-color);
    padding: 0;
    margin: 2rem 0 0;
    font-family: var(--font-family-heading);
    width: 100%;
}

.lead {
    font-weight: 400; /* 300 */
    font-size: var(--font-size-body);
    color: var(--text-muted);
    text-align: center;
    max-width: 540px;
    margin: 0 auto;
}


.containerPositionCenter {
    justify-content: center;
}

.containerPositionCenter .heading, .containerPositionCenter .lead {
    text-align: center;
}

.containerPositionLeft {
    justify-content: flex-start;
}

.containerPositionLeft .heading, .containerPositionLeft .lead {
    text-align: left;
    max-width: 720px;
    margin-left: 0;
    margin-right: auto;
}

.containerColorDark .heading {
    color: var(--dark-color);
}

.containerColorDark .lead {
    color: var(--text-muted);
}

.containerColorLight .heading {
    color: var(--light-color);
}

.containerColorLight .lead {
    color: var(--text-muted);
}
