.container {
    padding: 1.2rem;
    background-color: var(--light-color);
    border-radius: var(--border-radius-container);
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
}

.imgContainer {
    background-color: #D2E5F0;
    border-radius: calc(var(--border-radius-container) - 0.5rem);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.img {
    max-width: 61.8%;
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
}

.itemArrowImg:hover .img {
    transform: scale(1);
}

.metaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex: 1;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.titleText {
    font-size: var(--font-size-body);
    font-weight: 400;
    color: var(--dark-color);
    font-family: var(--font-family-heading);
}

.priceText {
    font-size: var(--font-size-h3);
    font-weight: 800;
    color: var(--dark-color);
    font-family: var(--font-family-heading);
}

.itemArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: auto;
    background-color: var(--secondary-color);
    border-radius: 100%;
    cursor: pointer;
    opacity: var(--opacity-idle);
    text-decoration: none;
}

.itemArrowImg {
    max-width: 100%;
    height: auto;
}

.itemArrowContainer:hover {
    opacity: var(--opacity-hover);
}

.itemArrowContainer:active {
    opacity: var(--opacity-active);
}

@media (max-width: 1200px) {
    .imgContainer {
        height: 100px;
    }
    .img {
        max-width: 90%;
    }
}

@media (max-width: 993px) {
    .imgContainer {
        height: 150px;
    }
    .img {
        max-width: 61%;
    }
}

@media (max-width: 600px) {
    .imgContainer {
        height: 120px;
    }
    .img {
        max-width: 80%;
    }
}

@media (max-width: 470px) {
    .metaContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .buttonContainer {
        width: 100%;
    }
    .itemArrowContainer {
        margin-left: auto;
    }

    .imgContainer {
        height: 70px;
    }
    .img {
        max-width: 100%;
    }
}