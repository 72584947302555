.slick-slide {
    opacity: 0.5;
    transition: all 0.4s ease-in-out;
    transform: scale(0.9);
}

.slick-slide.slick-active {
    opacity: 1;
    transform: scale(1);
}

.slick-slide div {
    display: flex;
}

.slick-track {
    align-items: center;
    justify-content: center;
    display: flex !important;
}