.container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: 1fr 2fr 1fr 2fr 1fr 2fr 1fr;
    gap: var(--gap-container);
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circleContainer1 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.circleContainer2 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}

.circleContainer3 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 5 / 6;
}

.circleContainer4 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 7 / 8;
}

.circle {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 100%;
    background: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.correction {
    margin-top: 1rem !important;
}

.arrowsContainer1 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.arrowsContainer2 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 4 / 5;
}

.arrowsContainer3 {
    composes: centerContainer;
    grid-column: 2 / 3;
    grid-row: 6 / 7;
}

.arrowImg {
    max-width: 30px;
    width: 100%;
    height: auto;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.headingText {
    font-size: var(--font-size-h3);
    font-weight: 600;
    color: var(--dark-color);
    font-family: var(--font-family-heading);
}

.textContainer1 {
    composes: textContainer;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.textContainer2 {
    composes: textContainer;
    grid-column: 3 / 4;
    grid-row: 4 / 6;
}

.textContainer3 {
    composes: textContainer;
    grid-column: 1 / 2;
    grid-row: 6 / 7;
}

@media (max-width: 700px) {
    .textContainer {
        text-align: center;
    }


    .container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 1fr);
    }



    .circleContainer1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .textContainer1 {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .arrowsContainer1 {
        display: none;
    }



    .circleContainer2 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .textContainer2 {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    .arrowsContainer2 {
        display: none;
    }



    .circleContainer3 {
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }

    .textContainer3 {
        grid-column: 1 / 2;
        grid-row: 6 / 7;
    }

    .arrowsContainer3 {
        display: none;
    }



    .circleContainer4 {
        grid-column: 1 / 2;
        grid-row: 7 / 8;
    }
}


.footerContainer {
    composes: textContainer;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
    text-align: center;
}

.icon {
    max-width: 2rem;
    width: 100%;
    height: auto;
    margin: auto;
}