.wrapper {
    position: relative;
}

.backgroundWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.whiteBackground {
    background-color: var(--light-color);
}

.gradientBackground {
    background: rgb(218,214,226);
    background: linear-gradient(180deg, rgba(218,214,226,1) 34%, rgba(255,133,72,1) 100%);
}

.contentWrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.contentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(var(--gap-container) * 3);
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.headingText {
    font-size: var(--font-size-xxl);
    font-weight: 800;
    color: var(--dark-color);
    margin: 0;
}

.sectionHeadingText {
    font-weight: 700;
    color: var(--dark-color);
    padding-top: 1rem;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    max-width: 60%;
    height: auto;
    border-radius: var(--border-radius-container);
}

@media (max-width: 1200px) {
    .image {
        max-width: 70%;
    }
}

@media (max-width: 992px) {
    .image {
        max-width: 80%;
    }
}

@media (max-width: 700px) {
    .contentContainer {
        grid-template-columns: 1fr;
        gap: var(--gap-container);
    }

    .gradientBackground {
        background: var(--light-color);
    }

    .image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        max-width: 100%;
    }

}