.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--gap-container);
    width: 100%;
    margin-top: 4rem;
}

.articleCardsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-container);
}

@media (max-width: 992px) {
    .articleCardsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 550px) {
    .articleCardsContainer {
        grid-template-columns: 1fr;
    }
}

.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
}

.paginationButton {
    cursor: pointer;
    font-weight: 700;
    color: var(--dark-color);
    opacity: var(--opacity-idle);
    font-size: var(--font-size-body);
}

.paginationActive {
    text-decoration: underline;
}

.paginationDisabled {
    pointer-events: none;
    cursor: not-allowed;
    color: var(--text-muted);
}

.paginationButton:hover {
    opacity: var(--opacity-hover);
}

.paginationButton:active {
    opacity: var(--opacity-active);
}

.skeleton {
    border-radius: var(--border-radius-container);
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: var(--gap-container);
}

.errorImage {
    max-width: 30px;
    width: 100%;
    height: auto;
}