.wrapper {
    width: 100%;
    padding: 4.5rem 0;
    background: #F2EFE6;
    color: var(--dark-color);
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap-container);
}

.headingText {
    font-size: var(--font-size-xxl);
    font-weight: 800;
    color: var(--dark-color);
    margin: 0;
    padding: 0;
}

.headingContainer {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 450px;
}

.contentHeadingText {
    font-weight: bold;
}

.contentText {

}