.testimonialContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: auto;
    gap: var(--gap-container);
    justify-content: center;
    align-items: center;
}

@media (max-width: 650px) {
    .testimonialContainer {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 550px) {
    .testimonialContainer {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

.testimonialItem {
    background-color: var(--light-color);
    padding: var(--padding-container);
    border-radius: var(--border-radius-container);
    display: flex !important;
    flex-direction: column;
    gap: var(--gap-container);
    box-sizing: border-box;
    break-inside: avoid;
}

.testimonialStars {
    display: flex;
    gap: 0.2rem;
}

.testimonialQuote {
    font-size: var(--font-size-h3);
    font-weight: 600;
}

.testimonialAuthor {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.testimonialAuthorDetails {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    justify-content: center;
}

.testimonialImage {
    border-radius: 100%;
    height: 50px;
    width: 50px;
}

.authorName {
    font-weight: 700;
}