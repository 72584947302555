.container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
}

.gridItem {
    background: var(--light-color);
    border-radius: var(--border-radius-container);
    display: flex;
    flex-direction: column;
}

.card1 {
    composes: gridItem;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.card2 {
    composes: gridItem;
    grid-column: 3 / 6;
    grid-row: 1 / 2;
}

.card3 {
    composes: gridItem;
    grid-column: 6 / 8;
    grid-row: 1 / 2;
}

.card4 {
    composes: gridItem;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
}

.card5 {
    composes: gridItem;
    grid-column: 5 / 8;
    grid-row: 2 / 3;
}

@media (max-width: 1200px) {
    .container {
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .card1 {
        composes: gridItem;
        grid-column: 1 / 4;
        grid-row: 1 / 3;
    }

    .card2 {
        composes: gridItem;
        grid-column: 4 / 7;
        grid-row: 1 / 2;
    }

    .card3 {
        composes: gridItem;
        grid-column: 7 / 10;
        grid-row: 1 / 2;
    }

    .card4 {
        composes: gridItem;
        grid-column: 4 / 7;
        grid-row: 2 / 3;
    }

    .card5 {
        composes: gridItem;
        grid-column: 7 / 10;
        grid-row: 2 / 3;
    }
}


@media (max-width: 992px) {
    .container {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .card1 {
        composes: gridItem;
        grid-column: 1 / 7;
        grid-row: 1 / 2;
    }

    .card2 {
        composes: gridItem;
        grid-column: 1 / 4;
        grid-row: 2 / 3;
    }

    .card3 {
        composes: gridItem;
        grid-column: 4 / 7;
        grid-row: 2 / 3;
    }

    .card4 {
        composes: gridItem;
        grid-column: 1 / 4;
        grid-row: 3 / 4;
    }

    .card5 {
        composes: gridItem;
        grid-column: 4 / 7;
        grid-row: 3 / 4;
    }
}


@media (max-width: 620px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
    }

    .card1 {
        composes: gridItem;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .card2 {
        composes: gridItem;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .card3 {
        composes: gridItem;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .card4 {
        composes: gridItem;
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    .card5 {
        composes: gridItem;
        grid-column: 1 / 2;
        grid-row: 5 / 6 ;
    }
}

