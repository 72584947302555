.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: var(--padding-container);
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
}

.headerImg {
    border-radius: 100%;
    max-width: 50px;
    width: 100%;
    height: auto;
}

.headerText {
    font-family: var(--font-family-heading);
    font-weight: 600;
    font-size: var(--font-size-h3);
    color: var(--dark-color);
}

.productsContainer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding-top: 1rem;
}

.productImg {
    width: 100%;
    max-width: 70%;
    padding: 0.785rem;
    height: auto;
}

.description {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.productContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    background-color: var(--light-color);
    border-radius: 1rem;
    padding: 0.6rem;
    max-width: 100px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    transform: scale(1);
    position: relative;
}

.productTitle {
    font-weight: 600;
    font-family: var(--font-family-heading);
    color: var(--dark-color);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    font-size: 75%;
    text-align: center;
}

.productContainer:hover {
    box-shadow: 0 2px 6px 1px rgba(202, 202, 209, 0.7);
    transform: scale(1.17);
    z-index: 2;
}

.productContainer:active {
    transform: scale(1.14);
}

.productContainer:hover .productTitle {
    opacity: 1;
}



@media (max-width: 768px) {
    .headerImg {
        max-width: 43px;
    }
    .header {
        gap: .65rem;
    }
}


.productCount {
    width: 1.35rem;
    height: 1.35rem;
    border-radius: 100%;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--light-color);
    font-size: 0.65rem;
    position: absolute;
    bottom: 0.6rem;
    right: 0.6rem;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.productContainer:hover .productCount {
    opacity: 1;
}