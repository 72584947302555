.wrapper {
    width: 100%;
    padding: 4.5rem 0;
    background: var(--dark-color);
    margin-top: var(--gap-container);
}

.container {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    gap: var(--gap-container);
}

.headingText {
    font-size: var(--font-size-h2);
    font-weight: 600;
    color: var(--light-color);
    font-family: var(--font-family-heading);
}

.leadText {
    font-size: var(--font-size-body);
    font-weight: 400;
    color: var(--light-color);
    font-family: var(--font-family-body);
}

.button {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: var(--font-size-body);
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid;
    border-color: var(--tertiary-color);;
    cursor: pointer;
    background-color: transparent;
    color: var(--light-color);
    opacity: var(--opacity-idle);
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}

.column {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: center;
}

.columnLeft {
    composes: column;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-items: flex-start;
}

.columnCenter {
    composes: column;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-items: center;
}

.columnRight {
    composes: column;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    align-items: flex-end;
}

.columnRight .leadText, .columnRight .headingText {
    text-align: right;
}

.columnRight .buttonContainer .button {
    border-color: var(--secondary-color);
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid;
}

.circleRight {
    composes: circle;
    border-color: var(--secondary-color);
    background-color: transparent;
}

.circleLeft {
    composes: circle;
    border-color: var(--tertiary-color);
    background-color: var(--tertiary-color);
}


.halfCircle {
    --half-circle-size: 110px;
    background: var(--tertiary-color);
    display: inline-block;
    margin: 0 1em 1em 0;
    height: var(--half-circle-size);
    width: calc(var(--half-circle-size) * 2);
    border-top-left-radius: calc(var(--half-circle-size) * 2);
    border-top-right-radius: calc(var(--half-circle-size) * 2);
    transform: rotate(-20deg) translateY(25px);
}

.halfCircleOutline {
    --half-circle-size: 110px;
    border: 3px solid var(--secondary-color);
    display: inline-block;
    margin: 0 1em 1em 0;
    height: var(--half-circle-size);
    width: calc(var(--half-circle-size) * 2);
    border-top-left-radius: calc(var(--half-circle-size) * 2);
    border-top-right-radius: calc(var(--half-circle-size) * 2);
    transform: rotate(160deg) translateY(25px);
}

@media (max-width: 1200px) {
    .halfCircle {
        --half-circle-size: 80px;
    }
    .halfCircleOutline {
        --half-circle-size: 80px;
    }
}

@media (max-width: 992px) {
    .halfCircle {
        --half-circle-size: 60px;
    }
    .halfCircleOutline {
        --half-circle-size: 60px;
    }
}

@media (max-width: 700px) {
    .container {
        grid-template-columns: 1fr;
    }
    .columnLeft {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    .columnCenter {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    .columnRight {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        align-items: flex-start;
    }
    .columnRight .leadText, .columnRight .headingText {
        text-align: left;
    }
    .halfCircle {
        --half-circle-size: 80px;
    }
    .halfCircleOutline {
        --half-circle-size: 80px;
    }
}