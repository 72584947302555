.someContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.someIcon {
    width: 25px;
    height: 25px;
    opacity: var(--opacity-idle);
    cursor: pointer;
    text-decoration: none;
}

.someIcon:hover {
    opacity: var(--opacity-hover);
}

.someIcon:active {
    opacity: var(--opacity-active);
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.link:active {
    text-decoration: none;
}