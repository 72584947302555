.wrapper {
    background: var(--primary-color);
    background: radial-gradient(circle, rgba(55,161,245,1) 0%, rgba(33,150,243,1) 79%, rgba(15,131,223,1) 100%);
    width: 100%;
    height: 650px;
    padding-top: calc(var(--gap-container) * 2);
}

.container {
    max-width: 768px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: var(--gap-container);
    width: 100%;
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.title {
    text-transform: uppercase;
    font-family: var(--font-family-heading);
    padding: 0;
    margin: 0;
    font-size: 2.5rem;
    font-weight: 900;
    color: var(--light-color);
}

.lead {
    padding: 0;
    margin: 0;
    font-size: 1.15rem;
    font-weight: 400; /* 300 */
    color: var(--light-color);
}

.socialProof {
    color: var(--light-color);
    margin-top: 0.5rem;
    font-size: calc(var(--font-size-body) * 0.85);
}

.headerImg, .headerImg1 {
    max-width: 400px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}

.headerImg1 {
    max-width: 300px;
    margin-bottom: unset;
}

.textContainer {
    margin-top: 155px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
}

.button {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: 1rem;
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1.5px solid;
    border-color: transparent;
    cursor: pointer;
    background-color: var(--light-color);
    color: var(--primary-color);
    margin-top: 0.5rem;
    opacity: var(--opacity-idle);
    box-shadow: 0 4px 4px 0 color(srgb 0.0816 0.4084 0.68);
}

.button:hover {
    opacity: var(--opacity-hover);
}

.button:active {
    opacity: var(--opacity-active);
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .column {
        align-items: center;
    }
    .textContainer {
        align-items: center;
        margin-top: 4.5rem;
    }
    .title {
        text-align: center;
        font-size: 2rem;
    }
    .lead {
        text-align: center;
    }
    .headerImg, .headerImg1 {
        margin-right: auto;
        margin-left: auto;
        max-width: 300px;
        margin-bottom: 2rem;
    }
    .headerImg1 {
        max-width: 250px;
        margin-top: 2rem;
        margin-bottom: 0;
    }
    .wrapper {
        height: auto;
    }
}
