.containerFluid {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: transparent;
}

.dropShadow {
    box-shadow: 0 1px 9px 2px color(srgb 0.0276 0.1607 0.29 / 0.24);
}

.animation {
    transition: background-color 0.15s ease-in-out;
}

.containerFluidPrimary {
    composes: containerFluid;
    background-color: var(--primary-color);
}

.containerFluidLight {
    composes: containerFluid;
    background-color: var(--light-color);
}


.logo {
    height: 100%;
    width: auto;
    margin: auto 0;
}

.logoLink {
    height: 40px;
    width: auto;
    text-decoration: none;
    cursor: pointer;
}

.header {
    display: flex;
    width: 100%;
    gap: 2rem;
    padding: 0.75rem 0;
}

.headerOpen {
    composes: header;
    flex-direction: column;
    height: 100%;
}

.headerInline {
    composes: header;
    flex-direction: row;
    height: auto;
    align-items: center;
}

.menuInline {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
}

.menuOpen {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    transition: all 0.3s ease;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 2.25rem;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    height: 100%;
}

.menuLink {
    text-decoration-line: none;
    opacity: 0.93;
    font-weight: 600;
    font-size: 1.05rem;
    transition: all 0.2s ease-out;
}

.menuLink:hover {
    opacity: 0.96;
}

.menuLink:active {
    opacity: 1;
}

.menuButtonsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-left: auto; /* Pushes the buttons to the right */
}

.menuButton {
    font-weight: var(--font-weight-button);
    font-family:  var(--font-family-button);
    font-size: 1.0rem;
    text-decoration-line: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-button);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1.5px solid;
    border-color: transparent;
    opacity: var(--opacity-idle);
}

.menuButton:hover {
    opacity: var(--opacity-hover);
}

.menuButton:active {
    opacity: var(--opacity-active);
}

.menuLinkLight {
    composes: menuLink;
    color: var(--dark-color) !important;
}

.menuLinkLight:hover {
    color: var(--primary-color) !important;
}

.menuLinkPrimary {
    composes: menuLink;
    color: var(--light-color) !important;
}

.menuButtonMainBgPrimary {
    composes: menuButton;
    color: var(--primary-color);
    background: var(--light-color);
}

.menuButtonMainBgLight {
    composes: menuButton;
    color: var(--light-color);
    background: var(--primary-color);
}

.menuButtonSecondaryBgPrimary {
    composes: menuButton;
    color: var(--light-color);
    background: transparent;
    border-color: var(--light-color);
}

.menuButtonSecondaryBgLight {
    composes: menuButton;
    color: var(--primary-color);
    background: transparent;
    border-color: var(--primary-color);
}

.menuMobileButton {
    composes: menuButton;
    padding: 0.3rem 0.35rem;
    font-size: 0.95rem;
}

.menuMobileButtonBgPrimary {
    composes: menuMobileButton;
    color: var(--primary-color);
    background: var(--light-color);
}

.menuMobileButtonBgLight {
    composes: menuMobileButton;
    color: var(--light-color);
    background: var(--primary-color);
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4.8px;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.hamburgerActive {
    composes: hamburger;
    gap: 0;
}

.menuMobileButtonsContainer {
    display: none;
}

.hamburger span {
    width: 1.5rem;
    height: 0.17rem;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    position: relative;
    transform-origin: 1px;
}

.lineBgPrimary {
    background: var(--light-color);
}

.lineBgLight {
    background: var(--dark-color);
}

/* Active state styles for the hamburger */
.hamburgerActive span:nth-child(1) {
    transform: translateY(-7.5px) rotate(45deg);
}

.hamburgerActive span:nth-child(2) {
    opacity: 0;
    display: none;
}

.hamburgerActive span:nth-child(3) {
    transform: translateY(5.5px) rotate(-45deg);
}

@media (max-width: 1200px) {
    .hamburger {
        display: flex;
    }

    .menuInline {
        display: none;
    }

    .menuOpen {
        display: flex;
    }

    .row {
        width: 100%;
        height: auto;
    }

    .menuButtonsContainer {
        margin-left: 0; /* Pushes the buttons to the left */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
    }

    .menuButton {
        width: 100%;
    }

    .menuMobileButtonsContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        margin-right: auto;
    }
}