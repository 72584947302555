.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: var(--padding-container);
    border-radius: var(--border-radius-container);
    background-color: var(--light-color);
}

.name {
    font-weight: 700;
}

.location {
    font-weight: 700;
}

.locationContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.colorSecondary {
    color: var(--secondary-color);
}

.specialization {
    margin-top: 0.75rem;
}