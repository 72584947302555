.wrapper {
    width: 100%;
    padding: 4.5rem 0;
    background: var(--dark-color);
}

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-container);
}


@media screen and (max-width: 768px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
}